import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import BankTransactionsService from '../../services/bank-transactions.service';
import { setTeachersBalances } from '../../actions/bank';
import { useTranslation } from 'react-i18next';
import usePhoneCheck from '../../hooks/usePhoneCheck';
import BankMobileBody from '../../components/ComponentTable/Table/BankMobileBody';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import BankTableBody from '../../components/ComponentTable/Table/BankTableBody';
import BankTableHead from '../../components/ComponentTable/Table/BankTableHead';
import BankTableToolbar from '../../components/ComponentTable/Table/BankTableToolbar'
import {
    Stack,
    Box,
    Table,
    TableContainer,
    Paper,
} from '@mui/material';
import BackButton from '../../components/BalanceComponent/BackButton';
import { showCurrentStatus } from '../../actions/user';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';

// Стилізований компонент Popper
const CustomPopper = styled(Popper)({
  '& .MuiAutocomplete-paper': {
    backgroundColor: '#424242',
    color: '#ffffff',
  },
  '& .MuiAutocomplete-option': {
    '&.Mui-selected': {
      backgroundColor: '#616161',
    },
    '&:hover': {
      backgroundColor: '#757575',
    },
  },
});


const headCells = [
    {
        id: 'checkbox', 
        numeric: false,
        disablePadding: false,
        label: '', 
        type: 'checkbox', 
    },
    {
        id: 'rowId',
        numeric: false,
        disablePadding: false,
        label: 'id',
        type: 'string',
    },
    {
        id: 'surname',
        numeric: false,
        disablePadding: false,
        label: 'bankTeachersPage.surname',
        type: 'string',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'bankTeachersPage.name',
        type: 'string',
    },
    {
        id: 'balance',
        numeric: false,
        disablePadding: false,
        label: 'bankTeachersPage.balance',
        type: 'string',
    },
    {
        id: 'studentBalances',
        numeric: false,
        disablePadding: false,
        label: 'bankTeachersPage.studentBalances',
        type: 'string',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'bankTeachersPage.status',
        type: 'status',
    },
    {
        id: 'quantity',
        numeric: true,
        disablePadding: false,
        label: 'bankTeachersPage.quantity',
        type: 'decimalInput',
    },
    {
        id: 'bankAccount',
        numeric: true,
        disablePadding: false,
        label: 'bankTeachersPage.bankAccount',
        type: 'link',
    },
];


function getRowData(data) {
    return data
        ? data.map((item) => ({
              error: false,
              rowId: item.teacher_id,
              name: item.teacher_name.split(' ').pop() || '',
              surname: item.teacher_name.split(' ')[0] || '',
              balance: item.teacher_balance || '',
              studentBalances: item.students_balances || '',
              quantity: 100,
              status: item.teacher_status,
          }))
        : [];
}

function BankTeachersPage() {
    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [teacher, setTeacher] = useState('');
    const [status, setStatus] = useState('');
    const [errorStates, setErrorStates] = useState({});
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const history = useHistory();
    const { t, i18n } = useTranslation();
    const user = useSelector((state) => state.bank);
    const balance = user.customer.balance || 0;
    const themeMode = useSelector((state) => state.user.theme);
    const popperComponent = themeMode === 'dark' ? (props) => <CustomPopper {...props} /> : undefined;

    const teacherBalancesInfo = useMemo(() => {
        return getRowData(user.customer.teachersBalances);
    }, [user]);
    const mobile = usePhoneCheck();

    useEffect(() => {
        setRows(teacherBalancesInfo);
    }, [teacherBalancesInfo]);

    const [rows, setRows] = useState(teacherBalancesInfo);

    const dispatch = useDispatch();
    
    const fetchTeachersBalances = async () => {
        await dispatch(setTeachersBalances());
    };

    useEffect(() => {
        fetchTeachersBalances();
    }, []);

    const handleQuantityChange = (id, value) => {
        // Знаходимо індекс рядка з вказаним id
        const rowIndex = rows.findIndex((row) => row.rowId === id);
        // Створюємо новий екземпляр масиву rows, щоб уникнути мутації стану безпосередньо
        const newRows = [...rows];
        // Оновлюємо значення 'quantity' для конкретного рядка
        newRows[rowIndex] = {
            ...newRows[rowIndex],
            quantity: parseFloat(value) || '',
        };
        // Оновлюємо значення 'quantity' для конкретного рядка
        setRows(newRows);

        // Оновлюємо стан помилок
        setErrorStates((prevState) => ({
            ...prevState,
            [id]: value === '',
        }));
    };

    const handleSelectAllClick = (event) => {
        if (loading) return;
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.rowId);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        if (loading) return;

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...selected, id];
        } else if (selectedIndex >= 0) {
            newSelected = selected.filter((itemId) => itemId !== id);
        }

        setSelected(newSelected);

        setErrorStates((prevState) => ({
            ...prevState,
            [id]: false, // Очищення стану помилки при кліку на чекбокс
        }));
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    async function handleSuccessClick() {

        // Оберемо транзакції з 0 баланс, якщо такі є виходимо з функції
        const hasEmptyQuantity = rows
            .filter((row) => isSelected(row.rowId))
            .some(row => !row.quantity);

        if (hasEmptyQuantity) {
            toast.error(t('bankStudentsPage.hasEmptyQuantity'));
            return;
        };

        // Відфільтровуємо транзакції для підтвердження
        const data = rows
            .filter((row) => isSelected(row.rowId))
            .map((el) => ({
                amount: Number(el.quantity),
                customerId: Number(el.rowId),
                note: '',
            }));

        const totalAmount = data.reduce(
            (accumulator, user) => accumulator + user.amount,
            0
        );
        if (totalAmount <= balance) {
            await Promise.all(
                data.map(async (item) => {
                    try {
                        await BankTransactionsService.TransactionById(item);
                    } catch (error) {
                        console.log(error);
                    }
                })
            );
        } else {
            toast.error(t('sorokoinsForHomework.notEnoughMoney'));
        }
        setSelected([]);
    }

    // Фільтрація
    const handleFilterChange = (newTeacher, newStatus) => {
        if (loading) return;

        let filteredRows = teacherBalancesInfo;

        if (newTeacher) {
            filteredRows = filteredRows.filter(
                (row) => row.surname === newTeacher
            );
        }
        if (newStatus) {
            filteredRows = filteredRows.filter(
                (row) => showCurrentStatus(row.status, {t,i18n,}) === newStatus
            );
        }

        setRows(filteredRows);
    };

    const handleBankAccountClick = (id) => {
        history.push(`/users/bank/${id}/teachers`);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <BackButton />
            <Paper sx={{ width: '100%', mb: 2, p: 3 }}>
                <Stack direction={mobile ? "column" : "row"} justifyContent="flex-start" spacing={2}>
                    <CustomAutocomplete
                        label={t('bankTeachersPage.teacherSurname')}
                        options={rows
                            .map((option) => option.surname)
                            .filter(
                                (el, index, arr) => arr.indexOf(el) === index
                            )}
                        onChange={(event, newTeacher) =>
                            handleFilterChange(newTeacher, status)
                        }
                        inputValue={teacher}
                        onInputChange={(event, newInputValue) => {
                            setTeacher(newInputValue);
                        }}
                    />
                    <CustomAutocomplete
                        label={t('bankTeachersPage.status')}
                        options={rows
                            .map((option) => showCurrentStatus(option.status, { t, i18n, }))
                            .filter(
                                (el, index, arr) => arr.indexOf(el) === index
                            )}
                        onChange={(event, newStatus) =>
                            handleFilterChange(teacher, newStatus)
                        }
                        inputValue={status}
                        onInputChange={(event, newInputValue) => {
                            setStatus(newInputValue);
                        }}
                    />
                </Stack>
                <BankTableToolbar
                    title={t('bankTeachersPage.selectedTeachers')}
                    titleTooltipSuccess={t('bankStudentsPage.tooltipTitle')}
                    numSelected={selected.length}
                    onSuccessClick={handleSuccessClick}
                    loading={loading}
                    selectedUserIds={selected}
                    rows={rows}
                />
                {mobile ? (
                    <BankMobileBody
                        rows={rows}
                        headCells={headCells}
                        isSelected={isSelected}
                        handleSelectAllClick={handleSelectAllClick}
                        handleQuantityChange={handleQuantityChange}
                        handleClick={handleClick}
                        showCurrentStatus={showCurrentStatus}
                        handleBankAccountClick={handleBankAccountClick}
                        mobile={mobile}
                    />
                ) : (
                    <TableContainer style={{ maxHeight: '59vh' }}>
                        <Table
                            stickyHeader
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                        >
                            <BankTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                headCells={headCells}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <BankTableBody
                                rows={rows}
                                headCells={headCells}
                                isSelected={isSelected}
                                handleClick={handleClick}
                                handleQuantityChange={handleQuantityChange}
                                handleBankAccountClick={handleBankAccountClick}
                                order={order}
                                orderBy={orderBy}
                            />
                        </Table>
                    </TableContainer>
                )}
               
            </Paper>
        </Box>
    );
}


export default BankTeachersPage;


