import store from './../store';
import API_ROOT from './../constants/bank-urlAPI';
import { OLD_API } from '../constants/oldApiUrl';
import Auth from './auth.service';
import { resume, emulation as Emulation } from './../actions/user';
import { getError, getSuccess, getErrorTranslate } from '../actions/toast';
import Types from '../constants/user';
import BankCustomersService from './bank-customers.service';
import { setToken } from '../actions/bank';
export const GET = (...args) => makeRequest('GET', ...args);
export const POST = (...args) => makeRequest('POST', ...args);
export const PUT = (...args) => makeRequest('PUT', ...args);
export const DELETE = (...args) => makeRequest('DELETE', ...args);

let retryCount = 0; // змінна для лічильника

const formData = (object) =>
    Object.keys(object).reduce((form, key) => {
        form.append(key, object[key]);
        return form;
    }, new FormData());

async function makeRequest(
    method,
    endpoint,
    rawData = false,
    multipart = false,
    sendEmulation = true,
    oldApi = false,
    noMessage = false,
    returnError = false
) {
    const { access_token, emulation } = store.getState().user;
    const { jwt } = store.getState().bank;
    const headers = oldApi
        ? new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
              Accept: 'application/json',
          })
        : new Headers({
              'Content-Type': 'application/json',
          });

    if (access_token && !emulation.length) {
        if (
            endpoint === '/customers/check-exist' ||
            endpoint === '/customers/registration'
        ) {
            headers.append('authorization', `Bearer ${access_token}`);
        } else {
            headers.append('authorization', `Bearer ${jwt}`);
        }
    }

    if (sendEmulation && emulation && emulation.length) {
        if (
            endpoint === '/customers/check-exist' ||
            endpoint === '/customers/registration'
        ) {
            headers.append(
                // дозволяємо емуляцію додавши 'authorization'
                'authorization',
                `Bearer ${emulation[emulation.length - 1].token}`
            );
        } else {
            headers.append('authorization', `Bearer ${jwt}`);
        }
    }

    const options = {
        method,
        headers,
        mode: 'cors',
        cache: 'default',
    };
    const body =
        !!rawData &&
        (multipart
            ? formData(rawData)
            : oldApi
            ? makeToQuery(rawData, true)
            : JSON.stringify(rawData));

    if (!!body) {
        Object.assign(options, { body });
    }
    const { user } = store.getState();
    const id_admin = user.info && user.info.role_id;

    const request = oldApi
        ? new Request(oldApi, options)
        : new Request(API_ROOT + endpoint, options);
    if (
        id_admin &&
        !endpoint.includes('auth') &&
        id_admin === 10 &&
        method !== 'GET'
    ) {
        return getErrorTranslate('error.no_access_to_fetch');
    }
    try {
        const data = await fetch(request);
        const json = await data.json(); 

        if (json && json.message === 'Card number does not exist') {
            const error = new Error(json.message || 'Error occurred');
            error.status = data.status;
            throw error;    
        };

        if (
            json &&
            json.statusCode === 401 &&
            json.message === 'Unauthorized'
        ) {
            if (retryCount < 3) {
                retryCount++; 
                const resBank = await BankCustomersService.GetCheckExist();
                if (resBank && resBank.jwt) {
                    store.dispatch(setToken(resBank));
                    return await makeRequest(method, endpoint, rawData, multipart);
                }
            }
        }
        if (json && !(json.statusCode || json.status === 404)) {       
            retryCount = 0; 
            if (
                method !== 'GET' &&
                !endpoint.includes('auth') &&
                !endpoint.includes('correct-schema-hw')
            ) {
                if (!noMessage && !returnError) getSuccess();
            }
            return json;
        } else if (json.error === 'Token is outdated') {
            const { user } = store.getState();
            const { refresh_token, isRefreshTokenUpdateProcess } = user;
            if (!isRefreshTokenUpdateProcess) {
                store.dispatch({
                    type: Types.RESUME,
                    user: {
                        ...user,
                        isRefreshTokenUpdateProcess: true,
                    },
                });
                return Auth.Resume({ refresh_token }).then(async (response) => {
                    resume(response)(store.dispatch, store.getState);
                    return await makeRequest(
                        method,
                        endpoint,
                        rawData,
                        multipart
                    );
                });
            } else {
                return new Promise(async (resolve) => {
                    const unsubscribe = store.subscribe(async () => {
                        const { isRefreshTokenUpdateProcess } =
                            store.getState().user;
                        if (!isRefreshTokenUpdateProcess) {
                            unsubscribe();
                            const newResponse = await makeRequest(
                                method,
                                endpoint,
                                rawData,
                                multipart
                            );
                            resolve(newResponse);
                        }
                    });
                });
            }
        } else if (json.error === 'Emulation token is outdated') {
            const { user } = store.getState();
            const { isEmulationTokenUpdateProcess } = user;
            if (!isEmulationTokenUpdateProcess) {
                store.dispatch({
                    type: Types.RESUME,
                    user: {
                        ...user,
                        isEmulationTokenUpdateProcess: true,
                    },
                });
                await Emulation({
                    id: user.emulation[emulation.length - 1].user_id,
                })(store.dispatch, store.getState);
                return await makeRequest(method, endpoint, rawData, multipart);
            } else {
                return new Promise(async (resolve) => {
                    const unsubscribe = store.subscribe(async () => {
                        const {
                            isEmulationTokenUpdateProcess,
                            isRefreshTokenUpdateProcess,
                        } = store.getState().user;
                        if (
                            !isEmulationTokenUpdateProcess &&
                            !isRefreshTokenUpdateProcess
                        ) {
                            unsubscribe();
                            const newResponse = await makeRequest(
                                method,
                                endpoint,
                                rawData,
                                multipart
                            );
                            resolve(newResponse);
                        }
                    });
                });
            }
        } else {
            const error = new Error(json.error);
            error.name = 'API';
            error.number = data.status;
            error.url = data.url;
            if ((json.statusCode || json.status === 404) && !noMessage) {
                getError({ ...json, message: json.message });
            }
            throw error;
        }
    } catch ({ message, name, number, url, ...props }) {
        retryCount = 0; 

        if (!!oldApi) {
            return true;
        }
        if (!noMessage && message) {
            getError({ message, name, number, url });
        }
        if (number === 403) {
            if (message === 'Token has been changed') {
                getError({ message, name, number, url });
                localStorage.setItem('globalStore', JSON.stringify({}));
                document.location = `${document.location.origin}/login`;
            } else {
                return Promise.reject({ message, name, number, url });
            }
        } else if (number === 401) {
            if (message === 'Token recovery is not subject to') {
                localStorage.setItem('globalStore', JSON.stringify({}));
                document.location.reload(true);
            }
        } else {
            return Promise.reject({ message, name, number, url });
        }
    }
}

const makeToQuery = (query, noGet = false) => {
    if (!query) return '';
    var str = [];
    for (let p in query)
        if (query.hasOwnProperty(p)) {
            str.push(
                encodeURIComponent(p) + '=' + encodeURIComponent(query[p])
            );
        }
    if (noGet) return str.join('&');
    return '?' + str.join('&');
};

export const ApiConnector = (prefix = '') => {
    return (
        callback,
        endpoint,
        multipart = false,
        query = false,
        sendEmulation = true,
        oldApi = false
    ) => ({
        async call(body = false, noMessage = false, returnError = false) {
            if (oldApi) {
                oldApi = OLD_API + endpoint;
            }
            try {
                return await callback(
                    ...[
                        prefix + endpoint + makeToQuery(query),
                        body,
                        multipart,
                        sendEmulation,
                        oldApi,
                        noMessage,
                        returnError,
                    ]
                );
            } catch (message) {
                if ((message && noMessage) || returnError) {
                    return Promise.reject(message);
                }
            }
        },
    });
};
