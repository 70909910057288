import { ApiConnector, GET, POST, PUT, DELETE } from './requestV2.service';


export default (() => {
  const API = ApiConnector("/chats", true);
  return {
    // support
    // повертає список клієнтів, котрі звертались в тех. підтримку (ліве меню)
    GetChats: async () => await API(GET, "").call(),
    // запит по ід_клієнта для отримання всіх чатів клієнта
    GetChatsByClientId: async (query) => await API(GET, `/${query}`).call(),
    // Закрити чат (заявку, після вирішення питання)
    CloseSupportTicket: async (query) => await API(DELETE, `/${query}`).call(),
    // Повторно відкрити чат по idChat
    ReopenChat: async (query) => await API(POST, `/open/${query}`).call(),

    // client
    // повертає історію чатів, активний і архівні
    GetMyChatHistory: async () => await API(GET, "/my").call(),
    // створює новий чат і повертає інформацію про нього
    СreateNewChat: async () => await API(POST, "/my").call(),

    // support & client
    // завантажити медіа файли на сервер
    UploadChatMedia: async (data) => await API(POST, "/upload", true).call(data),
    // робить мітку, що повідомлення прочитані, передаємо chatID
    MarkChatMessagesRead: async (query) => await API(POST, `/${query}`).call(),
  };
})();   

