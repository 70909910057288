import { io } from 'socket.io-client';
import store from './../store';
import { getError } from '../actions/toast';
import {
    addNewMessage,
    incrementChatTotalNotViewed,
    incrementChatTotalNotViewedBySupport,
} from '../actions/supportChat';
import Auth from './auth.service';
import Types from '../constants/user';
import { resume } from './../actions/user';
import API_SOCKET from './../constants/socketURL';

const SOCKET_URL = API_SOCKET;
const MAX_RECONNECT_ATTEMPTS = 5;

class SocketManager {
    constructor() {
        this.socket = null;
        this.currentToken = null;
        this.storeListener = 0;
        this.reconnectAttempts = 0;
    }

    async handleTokenOutdated() {
        const { user } = store.getState();
        const { refresh_token, isRefreshTokenUpdateProcess } = user;

        if (!isRefreshTokenUpdateProcess) {
            store.dispatch({
                type: Types.RESUME,
                user: {
                    ...user,
                    isRefreshTokenUpdateProcess: true,
                },
            });

            console.log('resume started');
            const response = await Auth.Resume({ refresh_token });

            resume(response)(store.dispatch, store.getState);
        } else if (!this.storeListener) {
            return new Promise((resolve) => {
                const unsubscribe = store.subscribe(() => {
                    const { isRefreshTokenUpdateProcess } =
                        store.getState().user;
                    this.storeListener = 1;

                    if (!isRefreshTokenUpdateProcess) {
                        if (this.storeListener) {
                            unsubscribe();
                            this.storeListener = 0;
                        }

                        this.reconnectSocketWithNewToken();
                        resolve();
                    }
                });
            });
        }
    }

    connectSocket() {
        const { access_token, isRefreshTokenUpdateProcess, info } =
            store.getState().user;
        const { role_id } = info;

        if (!access_token || (this.socket && this.socket.connected)) {
            return;
        }

        this.socket = io(SOCKET_URL, {
            extraHeaders: {
                auth: access_token,
            },
            reconnection: true,
            reconnectionDelay: 5000,
        });

        this.socket.on('connect', () => {
            console.log('connected');
            this.currentToken = access_token;
            // this.reconnectAttempts = 0;
        });

        this.socket.on('disconnect', () => {
            console.log('disconnect');
        });

        this.socket.on('error', (err) => {
            console.log(err);
            if (err.error === 'Token is outdated') {
                console.log('Token is outdated');
                this.handleTokenOutdated();
            }
        });

        this.socket.on('recMessage', (data) => {
            const state = store.getState();
            const activeChatId = state.suppotrChat.activeChat;
            const isChatOpen = activeChatId === data.chat_id;
            if (data) {
                store.dispatch(
                    addNewMessage(data.chat_id, data, data.messageId)
                );
                if (!isChatOpen && data.counterNotViewed) {
                    if (role_id === 10) {
                        store.dispatch(
                            incrementChatTotalNotViewedBySupport(data.sender_id)
                        );
                    } else {
                        store.dispatch(incrementChatTotalNotViewed());
                    }
                }
            }
        });
    }

    sendMessage(chatId, message, fileMimetype = null, fileUrl = null) {
        if (!this.socket || !this.socket.connected) {
            return;
        }

        const messageData = {
            chat_id: chatId,
            message: message,
            fileMimetype: fileMimetype,
            fileUrl: fileUrl,
        };

        this.socket.emit('sendMessage', messageData, (response) => {
            if (response.status !== 'ok') {
                getError({ message: response.error });
            }
        });
    }

    disconnectSocket() {
        if (this.socket) {
            this.socket.removeAllListeners();
            this.socket.disconnect();
            this.socket = null;
        }
    }

    reconnectSocketWithNewToken() {
        if (this.reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
            return;
        }

        console.log('trying to connect ');

        this.reconnectAttempts++;
        setTimeout(() => {
            this.connectSocket();
        }, 1000);
    }
}

const socketManager = new SocketManager();
export default socketManager;
