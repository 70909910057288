import user from './user';
import modalWindow from './modalWindow';
import cities from './cities';
import offices from './offices';
import users from './users.list';
import countries from './countries';
import roles from './roles';
import groups from './groups';
import finance from './finance';
import payments from './payments';
import AsyncTable from './AsyncTable';
import HW from './hw';
import discounts from './discounts';
import journal from './journal';
import books from './books';
import olympiad from './olympiad';
import help from './help';
import bank from './bank';
import suppotrChat from './supportChat';

export default {
    user,
    books,
    modalWindow,
    cities,
    offices,
    users,
    countries,
    roles,
    finance,
    groups,
    payments,
    AsyncTable,
    HW,
    discounts,
    journal,
    olympiad,
    help,
    bank,
    suppotrChat,
};
