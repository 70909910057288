import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import PaymentsComponent from './payments';
import FopBillComponent from './fopbill';
import DiscountsComponent from './sales';
import './Payments.css';

import { discountStatus, deleteDiscount } from '../../actions/discounts';
import { openRequestModal } from '../../actions/modals';
import { updateTableData } from '../../actions/AsyncTable';
import Tooltip from '@material-ui/core/Tooltip';
import {
    processDebt,
    getCountedResult,
    getPaymentsInfoResult,
} from '../../actions/payments';
import { editUser, getSingleUser } from '../../actions/getUsers';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import FaceIcon from '@material-ui/icons/Face';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import RefreshIcon from '@material-ui/icons/Refresh';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';

import master_card from '../../resources/img/matercard-logo.png';
import visa from '../../resources/img/visa-logo.png';
import { GetCurrentPrice, GetCurrentLiPrice } from '../../actions/cities';
import CityProperties from './CityProperies';
import DebtControl from './DebtControl';
import { customFilterValue } from '../../actions/AsyncTable';
import LtvService from '../../services/ltv.service';
import { CircularProgress, IconButton } from '@mui/material';
import { getCurrentCurrency } from '../../actions/currencies';
import { apiService } from '../../services/requestV2.service';

const initState = {
    payments: false,
    discounts: false,
    fop: false,
    debt: false,
    ltv: 0,
    fopList: {
        res: [],
        offices: [],
    },
    refer: [],
    family: [],
    debtCount: null,
    debt_relief_archive: null,
    debt_relief_graduate: null,
    role_id: null,
    discount_count: null,
    income: 0,
    refund: 0,
    debt_relief: 0,
    id: null,
    balance: null,
    loading: true,
};

class PaymentsPage extends React.Component {
    constructor(props) {
        super(props);
        const state = JSON.parse(JSON.stringify(initState));
        const role_id = this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.role_id
            : this.props.user.info.role_id;
        this.evtSource = null;
        state.role_id = role_id;
        state.payments = role_id >= 4;
        state.id = this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.user_id
            : this.props.user.info.user_id;
        this.state = state;
    }

    processInit() {
        const state = JSON.parse(JSON.stringify(initState));
        const role_id = this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.role_id
            : this.props.user.info.role_id;
        this.evtSource = null;
        state.role_id = role_id;
        state.payments = role_id >= 4;
        state.id = this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.user_id
            : this.props.user.info.user_id;
        this.setState(state);
    }

    componentWillUnmount() {
        if (this.evtSource) this.evtSource.close();
        apiService.cancelAllRequests();
    }

    componentDidMount() {
        this.getNewData();
    }

    async getNewData() {
        this.setState({ loading: true });
        this.processInit();
        if (this.state.role_id === 3) {
            await this.props.GetCurrentPrice();
            await this.props.GetCurrentLiPrice();
        }
        await this.processProfile();
        await this.getCountData();
        await this.getPaymentsInfoData();
        this.setState({ loading: false });
    }

    async processProfile() {
        const res = await getSingleUser(this.state.id);
        if (res) this.setState({ balance: res.balance });
    }

    clearState() {
        this.setState(initState);
    }

    async getCountData() {
        if (this.state.role_id === 3) {
            const res = await this.props.getCountedResult();
            if (!res) return;
            this.setState({
                debtCount: res.debt_relief,
                debt_relief_archive: res.debt_relief_archive,
                debt_relief_graduate: res.debt_relief_graduate,
                discount_count: res.discount,
            });
        }
    }

    async getPaymentsInfoData() {
        if (this.state.role_id === 3 || this.state.role_id === 4) {
            const res = await this.props.getPaymentsInfoResult();
            let resLtv = null;
            if (this.state.role_id !== 4) {
                resLtv = await LtvService.GetLTV();
            }
            if (!res || !resLtv) return;

            this.setState({
                income: Math.floor(+res.income),
                refund: Math.floor(+res.refund),
                ltv: resLtv && resLtv.ltv,
                debt_relief: Math.floor(+res.debt_relief),
            });
        }
    }

    handleChange = (item) => {
        this.props.customFilterValue([]);
        if (this.state.role_id !== 3) return;
        const schema = {
            payments: false,
            discounts: false,
            debt: false,
            fop: false,
        };
        schema[item] = !this.state[item];
        this.setState(schema);
    };

    render() {
        const { t } = this.props;

        const openModal = (item) => {
            this.props.openRequestModal({
                opened: true,
                data: {
                    id: this.state.id,
                    balance: this.state.balance,
                    text: item,
                    header: t('payments.title'),
                    modaltype: 'LiqpayModal',
                },
            });
        };
        const domainLast =
            document.location.hostname.split('.')[
                document.location.hostname.split('.').length - 1
            ];
        const currency = {
            ee: 'EUR',
            ru: 'РУБ',
            ua: 'ГРН',
            by: 'РУБ',
            md: 'LEI',
            com: 'CAD',
        };
        return (
            <div className="payments_accordion_data">
                <Box>
                    <h2
                        className="container-header"
                        style={{ marginLeft: '5px' }}
                    >
                        {t('payments.title')}
                    </h2>
                    <IconButton onClick={() => this.getNewData()}>
                        {this.state.loading ? (
                            <CircularProgress size={15} />
                        ) : (
                            <RefreshIcon />
                        )}
                    </IconButton>
                </Box>
                {this.state.role_id === 3 ? (
                    <>
                        <Box component="div" m={1}>
                            <Accordion
                                expanded={this.state.fop}
                                className={'paymentsAccordion'}
                            >
                                <AccordionSummary
                                    onClick={() => this.handleChange('fop')}
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography
                                        component="div"
                                        className="header_icon_title"
                                    >
                                        {t('payments.fop')}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        component="div"
                                        style={{ width: '100%' }}
                                    >
                                        {this.state.fop ? (
                                            <FopBillComponent />
                                        ) : null}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        <Box component="div" m={1}>
                            <DebtControl
                                handleChange={this.handleChange}
                                debt={this.state.debt}
                                debtCount={this.state.debtCount}
                                debtReliefArchive={
                                    this.state.debt_relief_archive
                                }
                                debtReliefGraduate={
                                    this.state.debt_relief_graduate
                                }
                                handleDebtCount={() => {
                                    this.getCountData();
                                }}
                            />
                        </Box>
                        <Box component="div" m={1}>
                            <Accordion
                                expanded={this.state.discounts}
                                className={'paymentsAccordion'}
                            >
                                <AccordionSummary
                                    onClick={() =>
                                        this.handleChange('discounts')
                                    }
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography
                                        component="div"
                                        className="header_icon_title"
                                    >
                                        {t('sales.title')}  
                                    </Typography>
                                    <Typography>
                                        {this.state.discount_count ? (
                                            <Badge
                                                badgeContent={
                                                    this.state.discount_count
                                                }
                                                style={{ marginLeft: '20px' }}
                                                color="primary"
                                            >
                                                <FaceIcon />
                                            </Badge>
                                        ) : null}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        component="div"
                                        style={{ width: '100%' }}
                                    >
                                        <Card style={{ width: '100%' }}>
                                            {this.state.discounts ? (
                                                <DiscountsComponent />
                                            ) : null}
                                        </Card>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </>
                ) : null}
                {this.state.role_id === 5 ? (
                    <Box component="div" m={1}>
                        {this.state.balance != null ? (
                            <Typography
                                component="div"
                                style={{ width: '100%' }}
                            >
                                <Card
                                    style={{ padding: '20px' }}
                                    className="debt_container"
                                >
                                    <h2>
                                        {t('payments.your_current_balance')} |{' '}
                                        <span
                                            className={
                                                +this.state.balance < 0
                                                    ? 'debt'
                                                    : 'ok'
                                            }
                                        >
                                            {this.state.balance}{' '}
                                            {currency[domainLast]}
                                        </span>
                                    </h2>
                                    {false &&
                                    +this.state.balance < 0 &&
                                    domainLast === 'ua' ? (
                                        <>
                                            <p>{t('payments.debt_descr')}</p>
                                            <p>{t('payments.devt_liqpay')}</p>
                                            <Button
                                                className="debt_button"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => openModal(false)}
                                            >
                                                {t('payments.payment_item')} |
                                                LiqPay
                                            </Button>
                                            <ul className="payments_methods">
                                                <li className="payments-payments__item">
                                                    <Button
                                                        className="payments-payments__button visa_modal"
                                                        type="button"
                                                        onClick={() =>
                                                            openModal('visa')
                                                        }
                                                    >
                                                        <img
                                                            alt="Visa"
                                                            src={visa}
                                                        />
                                                    </Button>
                                                </li>
                                                <li className="payments-payments__item">
                                                    <Button
                                                        className="payments-payments__button master_card_modal"
                                                        type="button"
                                                        onClick={() =>
                                                            openModal('master')
                                                        }
                                                    >
                                                        <img
                                                            alt="MasterCard"
                                                            src={master_card}
                                                        />
                                                    </Button>
                                                </li>
                                            </ul>
                                        </>
                                    ) : null}
                                </Card>
                            </Typography>
                        ) : (
                            <Skeleton
                                animation="wave"
                                variant="rect"
                                width={'100%'}
                                height={150}
                            />
                        )}
                    </Box>
                ) : null}

                <Box component="div" m={1}>
                    <Accordion
                        expanded={this.state.payments}
                        className={'paymentsAccordion'}
                    >
                        <AccordionSummary
                            onClick={() => this.handleChange('payments')}
                            expandIcon={
                                this.state.role_id === 4 ||
                                this.state.role_id === 5 ? null : (
                                    <ExpandMoreIcon />
                                )
                            }
                        >
                            <Typography
                                component="div"
                                className="header_icon_title"
                            >
                                {t('payments.payments_history')}
                            </Typography>
                            {this.state.role_id === 3 ||
                            this.state.role_id === 4 ? (
                                <Box mr={2}>
                                    <Tooltip
                                        placement="top"
                                        title={t('payments.incomes')}
                                    >
                                        <Badge
                                            badgeContent={this.state.income}
                                            showZero={true}
                                            max={10000000}
                                            style={{ marginLeft: '20px' }}
                                            color="primary"
                                        >
                                            <AttachMoneyIcon />
                                        </Badge>
                                    </Tooltip>
                                    <Tooltip
                                        placement="top"
                                        title={t('payments.refunds')}
                                    >
                                        <Badge
                                            badgeContent={this.state.refund}
                                            showZero={true}
                                            max={10000000}
                                            style={{ marginLeft: '40px' }}
                                            color="primary"
                                        >
                                            <RefreshIcon />
                                        </Badge>
                                    </Tooltip>
                                    <Tooltip
                                        placement="top"
                                        title={t('payments.debt_reliefs')}
                                    >
                                        <Badge
                                            badgeContent={
                                                this.state.debt_relief
                                            }
                                            showZero={true}
                                            max={10000000}
                                            style={{ marginLeft: '40px' }}
                                            color="primary"
                                        >
                                            <MoneyOffIcon />
                                        </Badge>
                                    </Tooltip>
                                    {this.state.role_id === 3 ? (
                                        <Tooltip
                                            placement="top"
                                            title={t('payments.ltv')}
                                        >
                                            <Badge
                                                badgeContent={this.state.ltv}
                                                showZero={true}
                                                max={10000000}
                                                style={{ marginLeft: '40px' }}
                                                color="primary"
                                            >
                                                <HowToRegIcon />
                                            </Badge>
                                        </Tooltip>
                                    ) : null}
                                </Box>
                            ) : null}
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography
                                component="div"
                                style={{ width: '100%' }}
                            >
                                <Card style={{ width: '100%' }}>
                                    <Fragment>
                                        {this.state.payments ? (
                                            <PaymentsComponent />
                                        ) : null}
                                    </Fragment>
                                </Card>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                {this.state.role_id === 3 ? (
                    <Box m={1}>
                        <CityProperties
                            recivedPrice={this.props.cityPrice}
                            liPrice={this.props.liPrice}
                        />
                    </Box>
                ) : null}
            </div>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        payments: state.payments,
        modalWindow: state.modalWindow,
        user: state.user,
        AsyncTable: state.AsyncTable,
        cityPrice: state.cities && state.cities.cityPrice,
        liPrice: state.cities && state.cities.liPrice,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openRequestModal,
                updateTableData,
                editUser,
                processDebt,
                discountStatus,
                deleteDiscount,
                getCountedResult,
                getPaymentsInfoResult,
                getSingleUser,
                GetCurrentPrice,
                GetCurrentLiPrice,
                customFilterValue,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(PaymentsPage));
