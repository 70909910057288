import { ApiConnector, GET, POST, PUT, DELETE } from './requestV2.service';

export default (() => {
    const API = ApiConnector('/user');
    return {
        UsersList: async (data, query) =>
            await API(GET, 's', false, query).call(data),
        ChangeStudentGroup: async (data, query) =>
            await API(PUT, '/change-group-student', false, query).call(data),
        ChangeStudentStatus: async (data, query) =>
            await API(PUT, '/change-status-student', false, query).call(data),
        SingleUser: async (data, query) =>
            await API(GET, '', false, query).call(data),
        EditUser: async (data, query) =>
            await API(PUT, '', false, query).call(data),
        DeleteUser: async (data) => await API(DELETE, '').call(data),
        AddUser: async (data) => await API(POST, '').call(data),
        GetStatisticsService: async (data) =>
            await API(GET, '/statistic', false).call(data),
        GetStatService: async (data) =>
            await API(GET, '/stat', false).call(data),
        GetARCards: async (data) => await API(GET, '/arcard', false).call(data),
        CallStatisticsData: async (data, query) =>
            await API(GET, '/teacher/stat', false, query).call(data),
        RelocateRecivedList: async (data, query) =>
            await API(GET, 's/relocate/received', false, query).call(data),
        RelocateSentList: async () =>
            await API(GET, 's/relocate/sent', false, false).call(false),
        RelocateReqestDelete: async (data) =>
            await API(POST, 's/relocate/annuled').call(data),
        RelocateConfirm: async (data) =>
            await API(POST, 's/relocate/confirm').call(data),
        RelocateSendRequest: async (data) =>
            await API(POST, 's/relocate/send').call(data),
        GetProfileByEmail: async (query) =>
            await API(GET, 's/discounts', false, query).call(false),
        issueArCard: async (data) =>
            await API(POST, '/issued/arcard').call(data),
    };
})();
