import React, { Fragment } from 'react';
import '../ModalEditWindow.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { closeRequestModal, openRequestModal } from '../../../actions/modals';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import {
    assignBook,
    getStudentBooks,
    getSingleStudentBooks,
} from '../../../actions/books';
import {
    getGroupsFromSelect,
    getStudentsFromGroup,
} from '../../../actions/groups';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { recordUid } from '../../../actions/books';
import DialogActions from '@material-ui/core/DialogActions';
import {
    Badge,
    Chip,
    Collapse,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { getError } from '../../../actions/toast';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { getSingleUser } from '../../../actions/getUsers';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
const regex =
    /[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}/;

export function processBooks(res) {
    const data = {
        1: `textbook`,
        2: `textbook`,
        k: `textbook_kids`,
        m: `textbook_mult`,
    };
    return res.map((el) => {
        const number = Number(el.topic);
        const label = number ? el.topic : el.topic.toLowerCase();
        return {
            label: data[label],
            number: number || '',
            uid: el.uid,
        };
    });
}

class AssignBookModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input: this.props.data.uid
                ? this.props.data.uid
                : this.props.books.uid,
            group: '',
            students: null,
            loading: true,
            student: '',
            open: false,
            assignedBooks: null,
            codeInputAdditionalError: '',
            relink: '',
            relinkedList: [],
            showRelinked: false,
        };
        const uid = this.props.data.uid;
        if (uid) this.getGroupsController();
    }

    componentDidMount() {
        this.getBooks();
    }

    getBooks = async () => {
        this.setState({ assignedBooks: null });
        const { emulation, info } = this.props.user;
        const role_id = emulation.length
            ? emulation[emulation.length - 1].info.role_id
            : info.role_id;
        let res;

        if (this.props.data.isNoStudent && !this.state.student) return;

        if (role_id === 4 || role_id === 3)
            res = await getStudentBooks({
                student_id: this.props.data.isNoStudent
                    ? this.state.student
                    : this.props.data.id,
            });
        else if (role_id === 5) res = await getSingleStudentBooks();
        let relinkedList;
        if (res && res.studentsBooks && res.studentsBooks.length) {
            relinkedList = res.studentsBooks.filter((el) =>
                el.uid.includes('RELINK')
            );
            this.setState({
                relinkedList: processBooks(relinkedList),
                assignedBooks: processBooks(
                    res.studentsBooks.filter((el) => !el.uid.includes('RELINK'))
                ),
            });
        }
    };

    getGroupsController = () => {
        this.setState({ loading: true, open: true });
        this.props.getGroupsFromSelect(false, { size: 9999 }).then(() => {
            this.setState({ loading: false, open: false });
        });
    };

    handleChange = (event) => {
        if (this.state.codeInputAdditionalError) {
            this.setState({
                codeInputAdditionalError: '',
            });
        }
        let input = event.target.value;
        const code = input.match(regex);
        if (
            code &&
            this.state.assignedBooks &&
            this.state.assignedBooks.length
        ) {
            const isBookAlreadyAssigned = this.state.assignedBooks.find(
                (el) => el.uid === input
            );
            if (isBookAlreadyAssigned) {
                this.setState({
                    codeInputAdditionalError: this.props.i18n.t(
                        'error.uid ALREADY LINKED TO student_id'
                    ),
                });
            }
        }
        if (code && input.includes('http')) {
            input = code[0];
        }
        this.setState({ input });
    };

    getStudents = (data) => {
        this.setState({ loading: true, open: true });
        this.props
            .getStudentsFromGroup(false, { size: 9999 }, { group_id: data })
            .then((res) => {
                this.setState({
                    loading: false,
                    students: res.list,
                    open: false,
                });
            });
    };

    sendAssignRequest = () => {
        const { t } = this.props;

        const role_id = this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.role_id
            : this.props.user.info.role_id;
        if (!this.state.input) return toast.error(t('books.enterCode'));
        if (this.state.input.includes(' '))
            return toast.info(t('books.enterRightCode'));
        if (
            role_id === 4 &&
            this.props.data.uid &&
            !this.state.student &&
            !this.state.group
        ) {
            return toast.info(t('books.enterAllFields'));
        }
        this.props
            .assignBook({
                uid: this.state.input,
                id:
                    role_id === 5
                        ? this.props.data.id
                        : this.props.data.uid
                        ? this.state.student
                        : this.props.data.id,
            })
            .then((res) => {
                let relinked;
                if (res.res && res.res.includes('_RE_LINKED TO')) {
                    relinked = getError({ message: res.res }, true);
                    if (relinked) {
                        this.setState({ relink: relinked });
                        setTimeout(() => {
                            this.setState({ relink: '' });
                        }, 5000);
                    }
                }
                this.props.recordUid(null);
                this.setState({ input: '' });
                this.getBooks();
            })
            .catch((e) => {
                const message = getError(e, true);
                if (message) {
                    this.setState({
                        codeInputAdditionalError: message,
                    });
                }
            });
    };

    handleOpenRelinkedUser = async (id) => {
        const { t } = this.props;
        const user = await getSingleUser(id);
        if (user && user.first_name && user.last_name) {
            this.props.closeRequestModal();
            setTimeout(() => {
                this.props.openRequestModal({
                    opened: true,
                    data: {
                        id: id,
                        // eslint-disable-next-line
                        header: `${t('groups.assign_book')} | ${
                            user.first_name
                        } ${user.last_name}`,
                        modaltype: 'AssignBookModal',
                    },
                });
            }, 500);
        }
    };

    render() {
        const { t, i18n } = this.props;
        const language = i18n.language;
        const groups =
            this.props.groups &&
            this.props.groups.select &&
            this.props.groups.select.list;
        const isBookCodeError =
            (!regex.test(this.state.input) && !!this.state.input) ||
            this.state.codeInputAdditionalError;
        const role_id = this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.role_id
            : this.props.user.info.role_id;
        return (
            <Fragment>
                <Collapse
                    in={
                        !!(
                            this.state.relinkedList &&
                            this.state.relinkedList.length
                        )
                    }
                >
                    <Tooltip title={`${t('modals.relinkedBooks')}`}>
                        <IconButton
                            onClick={() =>
                                this.setState({
                                    showRelinked: !this.state.showRelinked,
                                })
                            }
                        >
                            <Badge
                                badgeContent={this.state.relinkedList.length}
                            >
                                <RotateLeftIcon
                                    style={{
                                        transition: 'all 0.5s',
                                        transform: `rotate(${
                                            !this.state.showRelinked
                                                ? '180deg'
                                                : '0deg'
                                        })`,
                                    }}
                                />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                </Collapse>

                <Collapse in={this.state.showRelinked}>
                    <div style={{ marginBottom: '20px' }}>
                        <Typography
                            variant={'caption'}
                            style={{
                                marginBottom: '10px',
                                display: 'block',
                            }}
                        >
                            {`${t('modals.relinkedBooks')}:`}
                        </Typography>
                        {this.state.relinkedList &&
                            this.state.relinkedList.map((book, index) => {
                                return (
                                    <Tooltip title={book.uid} key={index}>
                                        <Chip
                                            className={'booksChips'}
                                            label={`${t(
                                                `books.${book.label}`
                                            )}${
                                                book.number
                                                    ? ` ${book.number}`
                                                    : ''
                                            }`}
                                            style={{
                                                marginRight: '5px',
                                                marginBottom: '5px',
                                                opacity: '0.8',
                                            }}
                                            onClick={() =>
                                                Number(role_id) !== 5
                                                    ? this.handleOpenRelinkedUser(
                                                          book.uid.split('_')[1]
                                                      )
                                                    : null
                                            }
                                            icon={<LinkOffIcon />}
                                        />
                                    </Tooltip>
                                );
                            })}
                    </div>
                </Collapse>
                <Collapse
                    in={
                        !!(
                            this.state.assignedBooks &&
                            this.state.assignedBooks.length
                        )
                    }
                >
                    <div style={{ marginBottom: '20px' }}>
                        <Typography
                            variant={'caption'}
                            style={{ marginBottom: '10px', display: 'block' }}
                        >
                            {t('books.assignedBooks')}:
                        </Typography>
                        {this.state.assignedBooks &&
                            this.state.assignedBooks.map((book, index) => {
                                return (
                                    <Tooltip title={book.uid} key={index}>
                                        <Chip
                                            className={'booksChips'}
                                            label={`${t(
                                                `books.${book.label}`
                                            )}${
                                                book.number
                                                    ? ` ${book.number}`
                                                    : ''
                                            }`}
                                            style={{
                                                marginRight: '5px',
                                                marginBottom: '5px',
                                            }}
                                        />
                                    </Tooltip>
                                );
                            })}
                    </div>
                </Collapse>
                <div className="row">
                    <TextField
                        onChange={this.handleChange}
                        value={this.state.input}
                        label={t('books.texbook_code')}
                        variant="outlined"
                        className="maskedInput"
                        error={isBookCodeError}
                        helperText={
                            isBookCodeError &&
                            this.state.codeInputAdditionalError
                        }
                        FormHelperTextProps={{
                            classes: {
                                root: 'custom-error-style',
                            },
                        }}
                    />
                </div>
                {this.props.data.uid ? (
                    <div className="row item select_full">
                        <Autocomplete
                            options={groups}
                            getOptionLabel={(option) => {
                                let status = typeof option.title == 'object';
                                if (status) {
                                    if (!option.title[language])
                                        for (let item of Object.keys(
                                            option.title
                                        )) {
                                            if (!!option.title[item])
                                                return `${option.title[item]}`;
                                        }
                                    else {
                                        return option.title[language];
                                    }
                                }
                                //
                                // if(status) return  ? `--${t("modals.no_transation")}--` :
                                return option.title;
                            }}
                            style={{ width: '100%' }}
                            onChange={(event, newValue) => {
                                this.setState({
                                    student: '',
                                    students: null,
                                    group: '',
                                    assignedBooks: null,
                                });
                                if (
                                    newValue !== null &&
                                    Object.keys(newValue).length
                                ) {
                                    this.setState({ group: newValue.id });
                                    this.getStudents(newValue.id);
                                }
                            }}
                            loading={this.state.loading}
                            disabled={this.state.loading}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('groups.title')}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {this.state.loading ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                    />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </div>
                ) : null}
                {this.state.students ? (
                    <Autocomplete
                        options={this.state.students}
                        getOptionLabel={(option) =>
                            `${option.first_name} ${option.last_name}`
                        }
                        style={{ width: '100%' }}
                        onChange={(event, newValue) => {
                            if (
                                newValue !== null &&
                                Object.keys(newValue).length
                            ) {
                                this.setState({ student: newValue.id });
                            } else {
                                this.setState({ student: '' });
                            }
                            setTimeout(() => {
                                this.getBooks();
                            }, 100);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('groups.students')}
                                variant="outlined"
                            />
                        )}
                    />
                ) : null}
                <Collapse in={!!this.state.relink}>
                    <Typography variant={'caption'}>
                        {this.state.relink}
                    </Typography>
                </Collapse>
                <DialogActions>
                    <Button
                        className="request-button orangeButton"
                        onClick={this.props.closeRequestModal}
                    >
                        {t('buttons.cancel')}
                    </Button>
                    <Button
                        className="request-button greenButton"
                        onClick={() => this.sendAssignRequest()}
                        disabled={isBookCodeError || !this.state.input}
                    >
                        {t('buttons.apply')}
                    </Button>
                </DialogActions>
            </Fragment>
        );
    }
}

const withForm = connect(
    (state) => ({
        books: state.books,
        groups: state.groups,
        user: state.user,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openRequestModal,
                closeRequestModal,
                assignBook,
                getGroupsFromSelect,
                getStudentsFromGroup,
                recordUid,
            },
            dispatch
        )
);

export default withTranslation()(withForm(AssignBookModal));
