import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import socketManager from '../../services/webSocket.service';
import { openRequestModal } from '../../actions/modals';
import {
    setChatsBySupport,
    setTotalNotViewedByClient,
} from '../../actions/supportChat';
import { CircularProgress, IconButton, Badge, Tooltip } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

export default function Support() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const roleId = useSelector((state) => state.user.info.role_id);
    const totalNotViewedByClient = useSelector(
        (state) => state.suppotrChat.totalNotViewed
    );
    const chats = useSelector((state) => state.suppotrChat.chats);
    const isLoading = useSelector((state) => state.suppotrChat.isLoading);
    const totalNotViewedBySupport = chats
        ?.filter((chat) => chat.status === 'active')
        .reduce((sum, chat) => sum + (chat.counterNotViewed || 0), 0);

    useEffect(() => {
        if (roleId === 10) {
            dispatch(setChatsBySupport());
        } else {
            dispatch(setTotalNotViewedByClient());
        }
        socketManager.connectSocket();
    }, [roleId]);

    const handleOpenSuppotrChatСlient = () => {
        dispatch(
            openRequestModal({
                opened: true,
                data: {
                    header: t('support.supportChat'),
                    modaltype: 'SupportChatСlient',
                },
            })
        );
    };

    const handleOpenSuppotrChat = () => {
        dispatch(
            openRequestModal({
                opened: true,
                data: {
                    header: t('support.supportChat'),
                    modaltype: 'SupportChat',
                },
            })
        );
    };

    return (
        <>
            <Tooltip title={t('support.support')} placement="bottom">
                <IconButton
                    onClick={
                        roleId === 10
                            ? handleOpenSuppotrChat
                            : handleOpenSuppotrChatСlient
                    }
                    aria-label={t('support.supportChat')}
                >
                    <Badge
                        badgeContent={
                            isLoading ? (
                                <CircularProgress size={15} color="inherit" />
                            ) : roleId === 10 ? (
                                totalNotViewedBySupport
                            ) : (
                                totalNotViewedByClient
                            )
                        }
                        color="primary"
                    >
                        <SupportAgentIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
        </>
    );
}
