export default {
    ADD_NEW_MESSAGE: 'ADD_NEW_MESSAGE',
    ADD_HISTORY_MESSAGE: 'add_history_message',
    CLOSE_TICKET: 'close_ticket',
    SET_CHATS_BY_SUPPORT: 'set_chats_by_support',
    SET_TOTAL_NOT_VIEWED_BY_CLIENT: 'set_total_not_viewed_by_client',
    INCREMENT_CHAT_TOTAL_NOT_VIEWED: 'increment_chat_total_not_viewed',
    INCREMENT_CHAT_TOTAL_NOT_VIEWED_BY_SUPPORT: 'increment_chat_total_not_viewed_by_support',
    SET_LOADING: 'set_loading',
    SET_ACTIVE_CHAT: "set_avtive_chat",
    CLEAR_ACTIVE_CHAT: "clear_active_chat",
};
