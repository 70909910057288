import * as React from 'react';
import { useMemo, useState } from 'react';
import i18n from 'i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import { AllHWTopic } from '../../../constants/hw';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import { IconButton, Stack } from '@mui/material';
import {
    ClearAllOutlined,
    CollectionsBookmarkOutlined,
} from '@mui/icons-material';

const AddsDataToTheMultTable = ({ data }) => {
    const [filter, setFilter] = useState({
        topic: '',
        game: '',
        digit: '',
        eabacus: '',
        base: '',
    });

    const defaultStatList = useMemo(() => {
        if (data.list.length) {
            return data.list.map((item) => [
                item.GameID,
                item.Topic,
                item.GameID.split('_')[1] || '',
                (item.GameID.includes('eab') && 'yes') || 'no',
                item.Act || '',
                item.Rate || '',
                item.GameID.includes('100')
                    ? '100'
                    : item.GameID.includes('50')
                    ? '50'
                    : '-',
            ]);
        }
        return [];
    }, [data.list]);

    let [processedStatData, setProcessedStatData] = useState(defaultStatList);

    const handleChange = (filterItem, e) => {
        const value = e.target.value;
        setFilter((prev) => ({ ...prev, [filterItem]: value }));
    };

    React.useEffect(() => {
        const { topic, game, digit, eabacus, base } = filter;

        const list = JSON.parse(JSON.stringify(defaultStatList));
        const filtredList = list.filter((el) => {
            return (
                (!game || el[0].includes(`${game}_`)) &&
                (!topic || el[1] === topic) &&
                (!digit || +el[2] === +digit) &&
                (!eabacus || el[3] === eabacus) &&
                (!base || el[6] === base)
            );
        });

        setProcessedStatData(filtredList);
    }, [filter]);

    const yesNo = useMemo(() => {
        return [
            { label: i18n.t('table.yes'), value: 'yes' },
            { label: i18n.t('table.no'), value: 'no' },
        ];
    }, []);

    const baseSchema = useMemo(() => {
        return [, { value: '500' }, { value: '400' },{ value: '100' }, { value: '50' }];
    }, []);

    return (
        <Box style={{ padding: '25px 0' }}>
            <Box style={{ padding: '25px 0' }}>
                <Box
                    style={{
                        marginBottom: '10px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h3">
                        {i18n.t('groups.filter')}
                    </Typography>

                    <IconButton
                        onClick={() =>
                            setFilter({
                                topic: '',
                                game: '',
                                digit: '',
                                eabacus: '',
                                base: '',
                            })
                        }
                    >
                        <ClearAllOutlined />
                    </IconButton>
                </Box>
                <Stack spacing={[0, 0, 0, 2]} direction="row" flexWrap={'wrap'}>
                    <FormControl
                        style={{ marginBottom: '5px' }}
                        variant="outlined"
                    >
                        <InputLabel id="game">
                            {i18n.t('table.game_type')}
                        </InputLabel>

                        <Select
                            labelId="game"
                            value={filter.game}
                            onChange={(e) => handleChange('game', e)}
                            style={{
                                width: 150,
                                marginRight: 20,
                            }}
                            label={i18n.t('table.game_type')}
                        >
                            <MenuItem value="">{'-'}</MenuItem>;
                            {['Ded', 'Flash', 'FlashMinus'].map((el, index) => (
                                <MenuItem key={index} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        style={{ marginBottom: '5px' }}
                        variant="outlined"
                    >
                        <InputLabel id="topic">
                            {i18n.t('groups.theme')}
                        </InputLabel>

                        <Select
                            labelId="topic"
                            value={filter.topic}
                            onChange={(e) => handleChange('topic', e)}
                            style={{
                                width: 150,
                                marginRight: 20,
                            }}
                            label={i18n.t('groups.theme')}
                        >
                            <MenuItem value="">{'-'}</MenuItem>;
                            {AllHWTopic.map((el, index) => (
                                <MenuItem
                                    key={index}
                                    value={el.label.replace(/\s/g, '')}
                                >
                                    {el.label.replace(/\s/g, '')}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        style={{ marginBottom: '5px' }}
                        variant="outlined"
                    >
                        <InputLabel id="digit">
                            {i18n.t('table.digit')}
                        </InputLabel>

                        <Select
                            labelId="digit"
                            value={filter.digit}
                            onChange={(e) => handleChange('digit', e)}
                            style={{
                                width: 150,
                                marginRight: 20,
                            }}
                            label={i18n.t('table.digit')}
                        >
                            <MenuItem value="">{'-'}</MenuItem>;
                            {[1, 2, 3, 4].map((el, index) => (
                                <MenuItem key={index} value={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl
                        style={{ marginBottom: '5px' }}
                        variant="outlined"
                    >
                        <InputLabel id="eabacus">
                            {i18n.t('table.abacus')}
                        </InputLabel>

                        <Select
                            labelId="eabacus"
                            value={filter.eabacus}
                            onChange={(e) => handleChange('eabacus', e)}
                            style={{
                                width: 150,
                                marginRight: 20,
                            }}
                            label={i18n.t('table.abacus')}
                        >
                            <MenuItem value="">{'-'}</MenuItem>
                            {yesNo.map((el, index) => (
                                <MenuItem key={index} value={el.value}>
                                    {el.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        style={{ marginBottom: '5px' }}
                        variant="outlined"
                    >
                        <InputLabel id="base">
                            {i18n.t('table.base')}
                        </InputLabel>

                        <Select
                            labelId="base"
                            value={filter.base}
                            onChange={(e) => handleChange('base', e)}
                            style={{
                                width: 150,
                                marginRight: 20,
                            }}
                            label={i18n.t('table.base')}
                        >
                            <MenuItem value="">{'-'}</MenuItem>
                            {baseSchema.map((el, index) => (
                                <MenuItem key={index} value={el.value}>
                                    {el.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </Box>
            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            {[
                                i18n.t('table.game_type'),
                                i18n.t('groups.theme'),
                                i18n.t('table.digit'),
                                i18n.t('table.abacus'),
                                i18n.t('table.actions'),
                                i18n.t('olympiad.speed'),
                                i18n.t('table.base'),
                            ].map((el, index) => (
                                <TableCell
                                    key={index}
                                    align="left"
                                    variant="head"
                                    style={{
                                        fontWeight: 600,
                                    }}
                                >
                                    {el}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {processedStatData.map((row, index) => (
                            <TableRow key={index}>
                                {row.map((item, index) => (
                                    <TableCell
                                        align="left"
                                        key={index}
                                        component="th"
                                        scope="row"
                                    >
                                        {item}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default AddsDataToTheMultTable;
