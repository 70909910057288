import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import '../../layout/Dash/Dash.css';
import { openRequestModal } from '../../actions/modals';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {
    getStatuses,
    getDiscountsCount,
    getNearStudentsBirthday,
} from '../../actions/user';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from '@material-ui/core/Paper';
import { withTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import base64url from 'base64url';
import { customFilterValue } from '../../actions/AsyncTable';
import { Box, Fade, Stack } from '@mui/material';
import { getBooks, getBooksColorsCount } from '../../actions/books';
import { booksSchema, colorsSchema } from '../../constants/booksConstant';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { Link } from 'react-router-dom';
import BalanceComponent from '../BalanceComponent';

class DashTeacherView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [0, 1, 2, 3, 4, 5, 6],
            studs: [0, 1, 2, 3, 4, 5, 6, 7],
            discounts: null,
            birth_date: null,
            today_birth: [],
        };
    }

    componentDidMount() {
        const id = this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.user_id
            : this.props.user.info.user_id;
        const uid = this.props.books.uid;
        if (uid) this.openModal(id, true);
        this.processStatuses();
        this.processNearBitrhDays();
        this.processDiscountsCount();
        this.processBooks();
    }

    async processBooks() {
        const getBooksResponse = await this.props.getBooks();
        let res = structuredClone(getBooksResponse);
        const colors = await getBooksColorsCount();
        if (!colors) return;
        delete colors.green;
        res = { ...res, ...colors };
        const data = booksSchema;

        let items = Object.keys(res)
            .map((el, i) =>
                data[el]
                    ? {
                          lang: data[Object.keys(res)[i]],
                          label:
                              /\d/.test(`${Object.keys(res)[i]}`) && `${i + 1}`,
                          count: res[Object.keys(res)[i]],
                          code: Object.keys(res)[i],
                          color: colorsSchema[Object.keys(res)[i]] || '',
                      }
                    : null
            )
            .filter(Boolean);
        this.setState({ items });
        return true;
    }

    async processNearBitrhDays() {
        const res = await this.props.getNearStudentsBirthday({ size: 9999 });
        if (res) {
            const today = new Date().getTime();
            const today_birth = [];
            for (let item of res.list) {
                const date = new Date(item.birth_date).getTime();
                if (
                    this.processDate(today, false) ===
                    this.processDate(date, false)
                ) {
                    today_birth.push(item);
                }
            }
            this.setState({ birth_date: res.list, today_birth });
        }
    }

    processDate(item, full) {
        let d = new Date(item);
        const _month =
            d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
        const _date = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();

        return full
            ? `${_date}.${_month}.${d.getFullYear()}`
            : `${_date}.${_month}`;
    }

    async processDiscountsCount() {
        const res = await this.props.getDiscountsCount();
        if (res) this.setState({ discounts: res.count });
    }

    async processStatuses() {
        let students = await this.props.getStatuses();
        let studs = [];
        const statuses = {
            0: 'ACTIVE',
            1: 'BLOCKED',
            2: 'RESERVED',
            3: 'ARCHIVED',
            4: 'ON_VACATION',
            5: 'NOT_CONFIRMED',
            6: 'CANDIDATE',
            7: 'GRADUATE',
        };
        if (students) {
            Object.keys(students).map((item, index) =>
                studs.push({ title: statuses[item], value: students[index] })
            );
            this.setState({ studs });
        } else {
            console.log('no_data');
        }
    }

    openModal(id, isNoStudent) {
        const { t } = this.props;
        this.props.openRequestModal({
            opened: true,
            data: {
                id: id,
                uid: this.props.books.uid,
                header: t('groups.assign_book'),
                modaltype: 'AssignBookModal',
                isNoStudent,
            },
        });
    }
    encodeFilter(data) {
        const encodedFilter = data.reduce((result, item) => {
            return item.values
                ? { ...result, [item.code]: item.values }
                : result;
        }, {});
        return base64url.encode(
            encodeURIComponent(JSON.stringify(encodedFilter))
        );
    }

    handleDiscountsCount(item) {
        this.props.history.push(`/students/${item}`);
    }
    processFilterStudents(item, value) {
        if (value) {
            const { user } = this.props;
            let custom_filter = this.props.custom_filter;
            let user_id = user.emulation.length
                ? user.emulation[user.emulation.length - 1].info.user_id
                : user.info.user_id;
            if (!!user.emulation.length) user_id = `${user_id}_emul`;
            if (!custom_filter) {
                custom_filter = { [user_id]: { '/students': [] } };
            } else if (custom_filter && !custom_filter[user_id]) {
                custom_filter[user_id] = { '/students': [] };
            } else if (
                custom_filter &&
                custom_filter[user_id] &&
                !custom_filter[user_id]['/students']
            ) {
                custom_filter[user_id] = { '/students': [] };
            }
            const index = custom_filter[user_id]['/students'].findIndex(
                (el) => el.code === 'status'
            );
            if (index !== -1) delete custom_filter[user_id]['/students'][index];
            custom_filter[user_id]['/students'] =
                custom_filter[user_id]['/students'].filter(Boolean);

            custom_filter[user_id]['/students'] = [
                ...custom_filter[user_id]['/students'],
                {
                    code: 'status',
                    label: 'table.status',
                    type: 'status_select',
                    values: JSON.stringify(item),
                },
            ];
            this.props.customFilterValue(custom_filter, true);

            const filter = this.encodeFilter([
                {
                    code: 'status',
                    label: 'table.status',
                    type: 'status_select',
                    values: JSON.stringify(item),
                },
            ]);
            this.props.history.push(`/students?filter=${filter}`);
        }
    }
    render() {
        const id = this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.user_id
            : this.props.user.info.user_id;
        const { t } = this.props;
        return (
            <>
                <BalanceComponent />

                <div className="container-form">
                    {this.props.books.uid ? (
                        <Card variant="outlined">
                            <CardContent>
                                <Box p={1}>
                                    <Stack spacing={1} alignItems={'start'}>
                                        <Typography color="textSecondary">
                                            {t('books.book_detected')}
                                        </Typography>
                                        <Typography variant="h6" component="h2">
                                            {this.props.books.uid}
                                        </Typography>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                this.openModal(id, true)
                                            }
                                            startIcon={<MenuBookIcon />}
                                        >
                                            {t('groups.assign_book')}
                                        </Button>
                                    </Stack>
                                </Box>
                            </CardContent>
                        </Card>
                    ) : null}

                    {this.state.today_birth.length ? (
                        <Paper
                            style={{ margin: '20px 20px 0px 0px' }}
                            className="animated fadeIn"
                        >
                            <Typography component="div" className="birth_date">
                                {t('dashboard.today_birthday')}
                            </Typography>
                            <>
                                {this.state.today_birth.map((el, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="birth_date_item"
                                        >
                                            {`${el.first_name} ${
                                                el.last_name
                                            }  |  ${this.processDate(
                                                el.birth_date,
                                                true
                                            )} 🥳🥳🥳`}
                                        </div>
                                    );
                                })}
                            </>
                        </Paper>
                    ) : null}

                    <section className="dashSection">
                        <Paper
                            style={{ margin: '20px 20px 0px 0px' }}
                            className="animated fadeIn"
                        >
                            <List component="nav" aria-label="mailbox folders">
                                <Typography
                                    variant="subtitle1"
                                    className="subtitle"
                                    gutterBottom
                                >
                                    {t('dashboard.stud_count')}
                                </Typography>
                                {!this.state.studs[0]
                                    ? this.state.studs.map((el, index) => {
                                          return (
                                              <ListItem divider key={index}>
                                                  <ListItem button>
                                                      <Skeleton
                                                          width={'100%'}
                                                          height={'40px'}
                                                          variant="text"
                                                      />
                                                  </ListItem>
                                              </ListItem>
                                          );
                                      })
                                    : this.state.studs.map((el, index) => {
                                          return (
                                              <ListItem
                                                  onClick={() =>
                                                      this.processFilterStudents(
                                                          index,
                                                          el.value
                                                      )
                                                  }
                                                  divider
                                                  key={index}
                                              >
                                                  <ListItem button>
                                                      <ListItemText
                                                          primary={t(
                                                              `users.${el.title}`
                                                          )}
                                                      />
                                                      <div className="counter">
                                                          {!el.value ? (
                                                              0
                                                          ) : (
                                                              <CountUp
                                                                  duration={
                                                                      2.75
                                                                  }
                                                                  end={el.value}
                                                              />
                                                          )}
                                                      </div>
                                                  </ListItem>
                                              </ListItem>
                                          );
                                      })}
                            </List>
                        </Paper>
                        <Paper
                            style={{ margin: '20px 20px 0px 0px' }}
                            className="animated fadeIn"
                        >
                            <List component="nav" aria-label="mailbox folders">
                                <Typography
                                    variant="subtitle1"
                                    className="subtitle"
                                    gutterBottom
                                >
                                    {t('sidebar.discounts')}
                                </Typography>
                                {this.state.discounts == null ? (
                                    <ListItem divider>
                                        <ListItem button>
                                            <Skeleton
                                                width={'100%'}
                                                height={'40px'}
                                                variant="text"
                                            />
                                        </ListItem>
                                    </ListItem>
                                ) : (
                                    <ListItem
                                        onClick={() =>
                                            this.handleDiscountsCount(
                                                'discounts'
                                            )
                                        }
                                        divider
                                    >
                                        <ListItem button>
                                            <ListItemText
                                                primary={t(
                                                    'dashboard.discounts_count'
                                                )}
                                            />
                                            <div className="counter">
                                                {!this.state.discounts ? (
                                                    0
                                                ) : (
                                                    <CountUp
                                                        duration={2.75}
                                                        end={
                                                            this.state.discounts
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </ListItem>
                                    </ListItem>
                                )}
                            </List>
                            <List component="nav" aria-label="mailbox folders">
                                <Typography
                                    variant="subtitle1"
                                    className="subtitle"
                                    gutterBottom
                                >
                                    {t('dashboard.birth_date')}
                                </Typography>
                                {this.state.birth_date == null ? (
                                    <ListItem divider>
                                        <ListItem button>
                                            <Skeleton
                                                width={'100%'}
                                                height={'40px'}
                                                variant="text"
                                            />
                                        </ListItem>
                                    </ListItem>
                                ) : (
                                    <ListItem
                                        onClick={() =>
                                            this.handleDiscountsCount(
                                                'birthdate'
                                            )
                                        }
                                        divider
                                    >
                                        <ListItem button>
                                            <ListItemText
                                                primary={t(
                                                    'dashboard.birth_date_title'
                                                )}
                                            />
                                            <div className="counter">
                                                {!this.state.birth_date
                                                    .length ? (
                                                    0
                                                ) : (
                                                    <CountUp
                                                        duration={2.75}
                                                        end={
                                                            this.state
                                                                .birth_date
                                                                .length
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </ListItem>
                                    </ListItem>
                                )}
                            </List>
                        </Paper>
                        <Fade in={true} timeout={1500}>
                            <Paper
                                style={{ margin: '20px 20px 0px 0px' }}
                                elevation={0}
                            >
                                <List
                                    component="nav"
                                    aria-label="mailbox folders"
                                >
                                    <Typography
                                        variant="subtitle1"
                                        className="subtitle"
                                        gutterBottom
                                    >
                                        {t('dashboard.books_stat')}
                                    </Typography>
                                    {!this.state.items[0]
                                        ? this.state.items.map((el, index) => {
                                              return (
                                                  <ListItem divider key={index}>
                                                      <ListItem button>
                                                          <Skeleton
                                                              width={'100%'}
                                                              height={'40px'}
                                                              variant="text"
                                                          />
                                                      </ListItem>
                                                  </ListItem>
                                              );
                                          })
                                        : this.state.items
                                              .filter(
                                                  (el) => el.code !== 'WITHOUT'
                                              )
                                              .map((el, index) => {
                                                  let text = t(
                                                      `books.${el.lang}`
                                                  );
                                                  const label = el.label
                                                      ? el.label
                                                      : '';
                                                  text = text + '  ' + label;
                                                  return (
                                                      <ListItem
                                                          divider
                                                          key={index}
                                                      >
                                                          <ListItem
                                                              button
                                                              component={Link}
                                                              to={`/books-stat?id=${el.code}`}
                                                          >
                                                              <ListItemText
                                                                  primary={text}
                                                              />
                                                              <div
                                                                  className="counter"
                                                                  style={{
                                                                      backgroundColor:
                                                                          el.color ||
                                                                          '',
                                                                  }}
                                                              >
                                                                  {!el.count ? (
                                                                      0
                                                                  ) : (
                                                                      <CountUp
                                                                          duration={
                                                                              2.75
                                                                          }
                                                                          end={
                                                                              el.count
                                                                          }
                                                                      />
                                                                  )}
                                                              </div>
                                                          </ListItem>
                                                      </ListItem>
                                                  );
                                              })}
                                </List>
                            </Paper>
                        </Fade>
                    </section>
                </div>
            </>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        user: state.user,
        cities: state.cities,
        books: state.books,
        modal: state.modalWindow.requestmodal.opened,
        custom_filter:
            state.AsyncTable.table && state.AsyncTable.table.custom_filter,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openRequestModal,
                getStatuses,
                getDiscountsCount,
                customFilterValue,
                getNearStudentsBirthday,
                getBooks,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(DashTeacherView));
