import React, { Fragment } from 'react';
import './PanelHeader.css';
import { connect } from 'react-redux';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ModalEditWindow from './../../components/ModalEditWindow';

import {
    closeModal,
    openModal,
    closeRequestModal,
    openRequestModal,
} from '../../actions/modals';
import IconButton from '@material-ui/core/IconButton';

import { bindActionCreators } from 'redux';
import { logOut, emulationBack } from '../../actions/user';
import { ExpandLess, ExpandMore, Person, VpnKey } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import SCHEMAS from './../../constants/modalSchemas';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import DevModeParent from './DevModeParent';
import { Stack } from '@mui/material';
import ThemeColorSwitch from './ThemeColorSwitch';
import LanguageChange from '../LanguageChange';
import logo from '../../constants/logo';
import NotificationCenter from '../NotificationCenter';
import Support from '../Support';

class PanelHeader extends React.Component {
    constructor(props) {
        super(props);
        let userinfo = this.props.user;
        const { i18n } = this.props;
        const lang_schema = { ru: 0, ua: 1, en: 2, ro: 5, by: 0, ee: 2 };
        let roles = userinfo.info.role.split('|||');
        let currentRole = roles[lang_schema[i18n.language]];
        this.state = {
            user: userinfo.info,
            role: currentRole,
            loader: false,
        };
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({ loader: true });
        }, 2000);
    }
    openModalHandler() {
        const user_id = this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.user_id
            : this.props.user.info.user_id;
        const { t } = this.props;
        this.props.openRequestModal({
            opened: true,
            data: {
                id: user_id,
                schema: SCHEMAS.editUser,
                header: t('modals_name.edit'),
                modaltype: 'EditUserModal',
            },
        });
    }
    render() {
        const role_id = this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.role_id
            : this.props.user.info.role_id;
        const emulation = this.props.user.emulation.length
        
        const { t, i18n } = this.props;
        const lang_schema = { ru: 0, ua: 1, en: 2, ro: 5, by: 0, ee: 2 };
        let langPos = lang_schema[i18n.language];
        const userData =
            this.props.user &&
            this.props.user.emulation &&
            this.props.user.emulation.length
                ? this.props.user.emulation[
                      this.props.user.emulation.length - 1
                  ]
                : this.props.user;
        let lang = { ru: 0, ua: 1, en: 2, ro: 5, by: 0, ee: 2 };
        const processLang = (data) => {
            let text = data.split('|||')[lang[i18n.language]];
            if (!text) {
                for (let _item of data.split('|||')) {
                    if (_item) {
                        text = _item;
                        break;
                    }
                }
            }
            return text ? text : `${i18n.t('modals.no_transation')}`;
        };
        const userMenuClose = (
            <Fragment>
                <div className="user-menu dropdown">
                    <div
                        className="dropdown-menu button"
                        onClick={this.props.openModal}
                    >
                        <div className="menu-default-view">
                            {userData.info.first_name} {userData.info.last_name}{' '}
                            -{' '}
                            {userData.info.role.split('|||')[langPos]
                                ? userData.info.role.split('|||')[langPos]
                                : t('modals.no_transation')}{' '}
                            {userData.info.city
                                ? ` | ${processLang(userData.info.city)}`
                                : null}
                        </div>
                        <div className="menu-mobile-view">
                            <Person />
                        </div>
                        <ExpandMore />
                    </div>
                </div>
            </Fragment>
        );

        const userMenuOpen = (
            <Fragment>
                <div className="user-menu-open dropdown">
                    <div
                        className="dropdown-menu button"
                        onClick={this.props.closeModal}
                    >
                        <div className="menu-default-view">
                            {userData.info.first_name} {userData.info.last_name}{' '}
                            -{' '}
                            {userData.info.role.split('|||')[langPos]
                                ? userData.info.role.split('|||')[langPos]
                                : t('modals.no_transation')}{' '}
                            {userData.info.city
                                ? ` | ${processLang(userData.info.city)}`
                                : null}
                        </div>
                        <div className="menu-mobile-view">
                            <Person className="animated fadeIn faster" />
                        </div>
                        <ExpandLess
                            className="animated fadeIn faster"
                            alt="close"
                        />
                    </div>
                    <div
                        className="modal_close_background"
                        onClick={this.props.closeModal}
                    />
                    <ul
                        className="ul-menu animated fadeIn faster"
                        onClick={this.props.closeModal}
                    >
                        <li>
                            <p>
                                {userData.info.first_name}{' '}
                                {userData.info.last_name} -{' '}
                                {userData.info.role.split('|||')[langPos]
                                    ? userData.info.role.split('|||')[langPos]
                                    : t('modals.no_transation')}{' '}
                                {userData.info.city
                                    ? ` | ${processLang(userData.info.city)}`
                                    : null}
                            </p>
                        </li>
                        {role_id !== 5 ? (
                            <li onClick={() => this.openModalHandler()}>
                                <Person className="usermenu-icon" alt="icon" />{' '}
                                <p> {t('header.profile')} </p>
                            </li>
                        ) : null}
                        <li id="logout" onClick={this.props.logOut}>
                            <ExitToAppIcon
                                className="usermenu-icon"
                                alt="icon"
                            />
                            <p> {t('header.change_user')} </p>
                        </li>
                        {this.props.user.emulation.length ? (
                            <li onClick={this.props.emulationBack}>
                                <VpnKey className="usermenu-icon" alt="icon" />
                                <p> {t('header.emulation_exit')} </p>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </Fragment>
        );

        const prevUser =
            !this.props.user.emulation.length ||
            this.props.user.emulation.length === 1
                ? this.props.user.info
                : this.props.user.emulation[
                      this.props.user.emulation.length - 2
                  ].info;

        return (
            <>
                <div className="header">
                    <DevModeParent>
                        <img src={logo[i18n.language]} alt="Soroban" />
                    </DevModeParent>

                    <div className="menu">
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            <NotificationCenter />
                            <ThemeColorSwitch />
                            {this.props.user.emulation.length ? (
                                <Tooltip
                                    placement="top"
                                    title={
                                        <div
                                            style={{
                                                whiteSpace: 'pre-line',
                                                textAlign: 'center',
                                            }}
                                        >{`${t('header.emulation_exit')}\n ${
                                            prevUser.first_name
                                        } ${prevUser.last_name} | ${
                                            prevUser.role.split('|||')[
                                                lang_schema[i18n.language]
                                            ]
                                        }`}</div>
                                    }
                                >
                                    <IconButton
                                        onClick={this.props.emulationBack}
                                        fontSize="small"
                                    >
                                        <Badge
                                            badgeContent={
                                                this.props.user.emulation.length
                                            }
                                            color="primary"
                                        >
                                            <ExitToAppIcon
                                                style={{
                                                    transform: 'rotate(180deg)',
                                                }}
                                            />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                            {this.props.modalWindow.usermenu
                                ? userMenuOpen
                                : userMenuClose}
                            {((role_id === 10 && !emulation)
                                || (role_id === 3 && !emulation)
                                || (role_id === 4 && !emulation))
                                && <Support />}
                            <LanguageChange />
                        </Stack>
                    </div>
                </div>
                <ModalEditWindow {...this.props} />
            </>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        user: state.user,
        modalWindow: state.modalWindow,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                closeRequestModal,
                openRequestModal,
                openModal,
                closeModal,
                logOut,
                emulationBack,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(PanelHeader));
