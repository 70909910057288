import Types from '../constants/supportChat';
import SupportChatService from '../services/supportChat.service';


function setActiveHistoryMessages(chatData) {
    return {
        type: Types.ADD_HISTORY_MESSAGE,
        payload: {
            chat_id: chatData.id,
            messages: chatData.messages,
        },
    };
}

function addNewMessage(chat_id, message, message_id) {   
    return {
        type: Types.ADD_NEW_MESSAGE,
        payload: {
            chat_id: chat_id,
            message: message,
            message_id: message_id,
        }
    };
}

function closeTicket(chat_id) {
    return {
        type: Types.CLOSE_TICKET,
        payload: {
            chat_id: chat_id,
        }
    };
}

function setChatsBySupport() {
    return async (dispatch) => {
        // Включаем лоадер перед запросом
        dispatch({ type: Types.SET_LOADING, payload: true });

        try {
            const res = await SupportChatService.GetChats();
            if (res && res.length) {
                dispatch({
                    type: Types.SET_CHATS_BY_SUPPORT,
                    payload: res,
                });
            } else {
                // Если данных нет, выключаем лоадер
                dispatch({ type: Types.SET_LOADING, payload: false });
            }
        } catch (error) {
            // console.error("Ошибка при загрузке чатов:", error);
            // В случае ошибки выключаем лоадер
            dispatch({ type: Types.SET_LOADING, payload: false });
        }
    };
}

function setTotalNotViewedByClient() {
    return async (dispatch) => {
        dispatch({ type: Types.SET_LOADING, payload: true });

        try {
            const res = await SupportChatService.GetMyChatHistory();
            if (res) {
                dispatch({
                    type: Types.SET_TOTAL_NOT_VIEWED_BY_CLIENT,
                    payload: res.counterNotViewed || 0,
                });
            } else {
                dispatch({ type: Types.SET_LOADING, payload: false });
            }
        } catch (error) {
            // console.error("Ошибка при загрузке истории чатов:", error);
            dispatch({ type: Types.SET_LOADING, payload: false });
        }
    };
}

function incrementChatTotalNotViewedBySupport(sender_id) {
    return {
        type: Types.INCREMENT_CHAT_TOTAL_NOT_VIEWED_BY_SUPPORT,
        payload: sender_id,
    };
}

function incrementChatTotalNotViewed() {
    return {
        type: Types.INCREMENT_CHAT_TOTAL_NOT_VIEWED,
        payload: 1,
    };
}

function setActiveChat(chatId) {
    return {
        type: "SET_ACTIVE_CHAT",
        payload: chatId,
    }
};

function clearActiveChat(chatId) {
    return async function (dispatch) {
        if (chatId) {
            await SupportChatService.MarkChatMessagesRead(chatId);
        }
        
        dispatch({
            type: "CLEAR_ACTIVE_CHAT",
        });
    };
};


export {
    setActiveHistoryMessages,
    addNewMessage,
    closeTicket,
    setChatsBySupport,
    setTotalNotViewedByClient,
    incrementChatTotalNotViewed,
    incrementChatTotalNotViewedBySupport,
    setActiveChat,
    clearActiveChat
};

